import React from 'react';

import Container from './../container/container';

import './banner.scss';
import { Link } from 'gatsby';

const Banner = ({buttonText, buttonLink, children}) => {
    const renderButton = () => {
        if(buttonText && buttonLink)
            return <Link to={buttonLink} className="line-button">{buttonText}</Link>
    }

    const bannerClass = buttonText && buttonLink ? 'banner link' : 'banner';

    return (
        <section className={bannerClass}>
            <Container>
                <div className="banner-content-wrapper">
                    <div className="banner-title">
                        {children}
                    </div>
                    {renderButton()}
                </div>
            </Container>
        </section>
    )
}

export default Banner;