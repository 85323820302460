import React from 'react';
import BackgroundImage from 'gatsby-background-image';

import Container from '../container/container';

import './subpage-header.scss';
import { graphql, useStaticQuery } from 'gatsby';

const SubpageHeader = ({title, subtitle, bg}) => {
    const data = useStaticQuery(graphql`
        {
            bg: file(relativePath: {eq: "services_bg.jpg"}) {
                id
                childImageSharp {
                    fluid(maxWidth: 1920) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)
    return (
        bg ? (
            <BackgroundImage className="subpage-header" fluid={data.bg.childImageSharp.fluid}>
                <Container>
                    <h1 className="border">
                        {title}
                    </h1>
                    <p className="subtitle">
                        {subtitle}
                    </p>
                </Container>
            </BackgroundImage>
        ) : (
            <div className="subpage-header">
                <Container>
                    <h1 className="border">
                        {title}
                    </h1>
                    <p className="subtitle">
                        {subtitle}
                    </p>
                </Container>
            </div>
        )

    )
}

export default SubpageHeader;