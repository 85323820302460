import React from "react"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown";

import Layout from '../components/layout';
import Contact from '../components/contact/contact';
import Banner from '../components/banner/banner';
import SubpageHeader from "../components/subpage-header/subpage-header";
import Container from "../components/container/container";

import '../styles/service.scss';
import servicesBg from '../components/assets/services_bg.jpg';
import Link from "../components/link";
import slugify from "../utils/slugify";

export const query = graphql`
  query ServiceQuery($id: String!) {
    strapiServices(strapiId: { eq: $id }) {
      serviceName
      serviceDescription
    }
    services: allStrapiServices {
        nodes {
            serviceName
            id
        }
    }
  }
`

const ServiceTemplate = ({data}) => {
    return (
        <Layout>
            <section className="single-service">
                <SubpageHeader title={data.strapiServices.serviceName} bg/>
                <Container>
                    <div className="content">
                        <ReactMarkdown source={data.strapiServices.serviceDescription} />
                    </div>
                    <div className="sidebar">
                        <div className="sidebar-wrapper">
                            <div className="title">OFERTA</div>
                            <div className="all-services">
                                <ul>
                                    {data.services.nodes.map(service => <li key={service.id}><Link to={`/oferta/${slugify(service.serviceName)}`}>{service.serviceName}</Link></li>)}
                                </ul>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
            <Banner >
                Masz pytania dotyczące oferty? Zadzwoń już teraz <strong>+48 509 94 88 11</strong>
            </Banner>
            <Contact />
        </Layout>
    )
}

export default ServiceTemplate;