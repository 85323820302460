import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import ReCAPTCHA from "react-recaptcha";

import Container from '../container/container';
import './contact.scss';

const handleFocus = e => e.target.parentNode.classList.add('focused');
const handleBlur = e => e.target.parentNode.classList.remove('focused');

const Contact = () => {
    const [formStatus, setFormStatus] = useState('test');
    const [recaptchaState, setRecaptchaState] = useState(null);
    const { register, handleSubmit, formState, reset } = useForm({
        mode: 'all',
        defaultValues: {
            name: '',
            email: '',
            message: ''
        }
    });

    const { errors, dirtyFields, isValid } = formState;

    const onSubmit = data => {
        
        fetch("https://konto-jarocin.herokuapp.com/sendmail", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify({
              ...data,
              "subject": "Wiadomość z formularza kontaktowego na stronie konto-jarocin.pl",
              "template": "defaultTemplate",
            }),
          })
            .then(function (response) {
                changeFormStatus('success')
                reset();
            })
            .catch(function (error) {
                changeFormStatus('error')
                console.error(error);
            });
    }

    const verifyCallback = value => setRecaptchaState(value);
    const expiredCallback = () => setRecaptchaState(null);
    const onloadCallback = () => console.log('reCAPTCHA loaded successfully!');

    const changeFormStatus = (status) => {
        setFormStatus(status);
        setTimeout(() => setFormStatus(null), 5000);
    }
    
    return (
        <section id="contact" className="contact">
            <Container>
                <div className="contact-title">
                    Wyprzedź z nami <strong>konkurencję</strong>
                </div>
                <div className="contact-wrapper">
                    <div className="contact-infos">
                        <h2 className="light">Kontakt</h2>
                        <p>Tel: <strong>509 94 88 11</strong></p>
                        <p>Adres: <strong>Jarocin, ul. Jarmarczna 16</strong></p>
                        <p>Email: <strong>artur.tomczak@konto-jarocin.pl</strong></p>
                    </div>
                    <div className="contact-form">
                        <h2 className="light">Zapytaj o wycenę</h2>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className={`form-control input ${dirtyFields.name ? 'filled' : ''}`} onFocus={handleFocus} onBlur={handleBlur}>
                                <label htmlFor="name">{`Imię ${errors.name ? ` - To pole jest wymagane!` : ''}`}</label>
                                <input type="text" name="name" id="name" ref={register({required: true})}/>
                            </div>
                            <div className={`form-control input ${dirtyFields.email ? 'filled' : ''}`} onFocus={handleFocus} onBlur={handleBlur}>
                                <label htmlFor="email">{`Email ${errors.email ? errors.email.type === 'pattern' ? ` - Adres email jest nieprawidłowy!` : ` - To pole jest wymagane!` : ''}`}</label>
                                <input type="email" name="email" id="email" ref={register({required: true, pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/})}/>
                            </div>
                            <div className={`form-control textarea ${dirtyFields.message ? 'filled' : ''}`} onFocus={handleFocus} onBlur={handleBlur}>
                                <label htmlFor="message">{`Wiadomość ${errors.message ? ` - To pole jest wymagane!` : ''}`}</label>
                                <textarea name="message" id="message" ref={register({required: true})}/>
                            </div>
                            <div className="form-checkbox">
                                <input type="checkbox" name="form-privacy" id="form-privacy" ref={register({required: true})}/>
                                <label htmlFor="form-privacy"><span style={{color: "red", fontSize: "2em"}}>*</span> Oświadczam, że wyrażam zgodę na przetwarzanie moich danych osobowych zawartych w formularzu przez Biuro Usług Finansowo-Księgowych "KONTO" Artur Tomczak z siedzibą w Jarocinie (63-200), ul. Jarmarczna 16 oraz Partnerów współpracujących z Biuro Usług Finansowo-Księgowych "KONTO" Artur Tomczak</label>
                            </div>
                            <div className="recaptcha-container">
                                <ReCAPTCHA
                                    sitekey="6LeKYD4aAAAAADoZQGFxSt9yeC9QYXR0d89wd3ZE"
                                    render="explicit"
                                    type="image"
                                    hl="pl"
                                    onloadCallback={onloadCallback}
                                    verifyCallback={verifyCallback}
                                    expiredCallback={expiredCallback}
                                    style={{float: "right"}}
                                />
                                <button type="submit" className="line-button" style={{fontWeight: '400', float: 'right'}} disabled={!(isValid && recaptchaState !== null)}>Wyślij zapytanie</button>
                            </div>
                            {formStatus === 'success' && <div style={{color: 'green'}}>{`Wiadomość została pomyślnie wysłana.`}</div>}
                            {formStatus === 'error' && <div style={{color: 'red'}}>{`Wystąpił problem podczas wysyłania wiadomości.`}</div>}
                        </form>
                    </div>
                </div>
            </Container>
        </section>
    )
}

export default Contact;